<section>
  <emi-popup-header *ngIf="!customHeader; else projectedPopupHeader" (closeDialog)="close()"></emi-popup-header>

  <emi-popup-body *ngIf="!customBody; else projectedPopupBody"></emi-popup-body>
</section>

<emi-popup-footer
  *ngIf="!customFooter; else projectedPopupFooter"
  [isLoading]="isLoading"
  (primaryBtnClicked)="handlePrimaryBtnClick()"
  (secondaryBtnClicked)="handleSecondaryBtnClick()"
></emi-popup-footer>

<ng-template #projectedPopupHeader>
  <ng-content select="emi-popup-header"></ng-content>
</ng-template>

<ng-template #projectedPopupBody>
  <ng-content select="emi-popup-body"></ng-content>
</ng-template>

<ng-template #projectedPopupFooter>
  <ng-content select="emi-popup-footer"></ng-content>
</ng-template>
