import { ChangeDetectionStrategy, Component, ContentChild, EventEmitter, Inject, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CustomPopupContentDirective } from '../../directives/custom-popup-content.directive';
import { EmiPopupConfig } from '../../models/popup.models';

@Component({
  selector: 'emi-popup-header',
  templateUrl: './popup-header.component.html',
  styleUrls: ['./popup-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PopupHeaderComponent {
  @Output() closeDialog: EventEmitter<void> = new EventEmitter();

  @ContentChild(CustomPopupContentDirective) protected customContent?: CustomPopupContentDirective;

  constructor(@Inject(MAT_DIALOG_DATA) public readonly config: EmiPopupConfig) {}
}
