import { ChangeDetectionStrategy, Component, ContentChild, Inject } from '@angular/core';
import { CustomPopupContentDirective } from '../../directives/custom-popup-content.directive';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EmiPopupConfig } from '../../models/popup.models';

@Component({
  selector: 'emi-popup-body',
  templateUrl: './popup-body.component.html',
  styleUrls: ['./popup-body.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PopupBodyComponent {
  @ContentChild(CustomPopupContentDirective) protected customContent?: CustomPopupContentDirective;

  constructor(@Inject(MAT_DIALOG_DATA) public readonly config: EmiPopupConfig) {}
}
