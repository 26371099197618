export enum EMIEnvironmentName {
  DEV = 'dev',
  LOCAL = 'local',
  STAGE = 'stage',
  PROD = 'prod',
}

export interface EMIEnvironment {
  name: EMIEnvironmentName;
  apiUrl: string;
}
