import { MatDialogConfig } from '@angular/material/dialog';
import { EmiPopupConfig } from '../models/popup.models';

export const DEFAULT_POPUP_PANEL_CONFIG: MatDialogConfig = {
  width: '90%',
  maxWidth: '400px',
  maxHeight: '80vh',
  panelClass: 'emi-dialog-panel',
};

export const SUCCESS_CONFIG: Partial<EmiPopupConfig> = {
  title: 'Success',
  body: 'Operation completed successfully',
  hasPrimaryBtn: true,
  hasSecondaryBtn: false,
};

export const ERROR_CONFIG: Partial<EmiPopupConfig> = {
  title: 'Error',
  body: 'Something went wrong',
  hasPrimaryBtn: true,
  primaryBtnText: 'Got it!',
  hasSecondaryBtn: false,
};
