<button
  (click)="onClick()"
  class="button button--{{ type }} button--{{ state }} button--{{ size }}"
  [ngClass]="{ 'button--loading': isLoading, 'button--disabled': isDisabled }"
>
  <div class="button__content">
    <mat-spinner *ngIf="isLoading" [color]="type === 'primary' ? 'accent' : 'primary'" [diameter]="16"></mat-spinner>

    <ng-content></ng-content>

    <span *ngIf="number" class="button__label">{{ number }}</span>
  </div>
</button>
