import { NgModule, inject } from '@angular/core';
import { AuthGuard } from '@core/guards/auth.guard';
import { RouterModule, Routes } from '@angular/router';
import { APP_RC } from '@core/constants/routes.constants';
import { PreloadingStrategyService } from '@core/services/preloading-strategy.service';

const routes: Routes = [
  {
    path: APP_RC.children.auth.root,
    loadChildren: () => import('@features/auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: APP_RC.children.authorizedContent.root,
    loadChildren: () =>
      import('@features/authorized-content/authorized-content.module').then((m) => m.AuthorizedContentModule),
    canMatch: [() => inject(AuthGuard).canMatch()],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadingStrategyService })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
