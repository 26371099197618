<div class="popup-header" *ngIf="!customContent; else customHeaderContent">
  <h4 class="popup-header__title lg-title-text">{{ config.title }}</h4>

  <span
    class="popup-header__close-button cursor-pointer"
    inlineSVG="assets/icons/close-icon.svg"
    (click)="closeDialog.emit()"
  ></span>
</div>

<ng-template #customHeaderContent>
  <ng-content select="[emiCustomPopupContent]"></ng-content>
</ng-template>
