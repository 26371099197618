import { Injectable } from '@angular/core';
import { LogInPayload, LogInResponse, RefreshTokenResponse } from '@core/models/entities/auth.models';
import { ProfileApi } from './profile.api';
import { Observable, tap } from 'rxjs';
import { HttpApi } from './http.api';

const feature: string = 'admin';

const ACCESS_TOKEN_KEY: string = 'access_token';

const REFRESH_TOKEN_KEY: string = 'refresh_token';

@Injectable({ providedIn: 'root' })
export class AuthApi {
  constructor(private readonly httpApi: HttpApi, private profileApi: ProfileApi) {}

  logIn(payload: LogInPayload): Observable<LogInResponse> {
    return this.httpApi.post<LogInResponse>(`${feature}/login`, payload).pipe(
      tap((response: LogInResponse) => {
        this.setAccessToken(response.accessToken);
        this.setRefreshToken(response.refreshToken);
        this.profileApi.user = response.user;
      })
    );
  }

  getAccessToken(): string | null {
    return localStorage.getItem(ACCESS_TOKEN_KEY);
  }

  getRefreshToken(): string | null {
    return localStorage.getItem(REFRESH_TOKEN_KEY);
  }

  refreshToken(): Observable<RefreshTokenResponse> {
    const refreshToken: string | null = this.getRefreshToken();

    return this.httpApi
      .post<RefreshTokenResponse>(`${feature}/refresh-token`, { refreshToken }, { skipSuccessMessage: true })
      .pipe(
        tap((response: RefreshTokenResponse) => {
          this.setAccessToken(response.accessToken);
          this.setRefreshToken(response.refreshToken);
        })
      );
  }

  logOut(): Observable<boolean> {
    return this.httpApi
      .post<boolean>(`${feature}/logout`, {}, { skipSuccessMessage: true })
      .pipe(tap(() => this.reset()));
  }

  isAuthorized(): boolean {
    return !!this.getAccessToken();
  }

  private setAccessToken(token: string | null): void {
    if (token) {
      return localStorage.setItem(ACCESS_TOKEN_KEY, token);
    }

    localStorage.removeItem(ACCESS_TOKEN_KEY);
  }

  private setRefreshToken(token: string | null): void {
    if (token) {
      return localStorage.setItem(REFRESH_TOKEN_KEY, token);
    }

    localStorage.removeItem(REFRESH_TOKEN_KEY);
  }

  private reset(): void {
    this.setAccessToken(null);
    this.setRefreshToken(null);
    this.profileApi.user = null;
  }
}
