import { Injectable } from '@angular/core';
import { User } from '@core/models/entities/user.models';
import { BehaviorSubject, Observable, tap } from 'rxjs';
import { HttpApi } from './http.api';

const feature: string = 'admin/profile';

@Injectable({ providedIn: 'root' })
export class ProfileApi {
  private readonly _user$ = new BehaviorSubject<User | null>(null);

  readonly user$: Observable<User | null> = this._user$.asObservable();

  constructor(private readonly httpApi: HttpApi) {}

  load(): Observable<User> {
    return this.httpApi.get<User>(feature).pipe(tap((user: User) => this._user$.next(user)));
  }

  get user(): User | null {
    return this._user$.value;
  }

  set user(user: User | null) {
    this._user$.next(user);
  }
}
