import { NgModule, Optional, Provider, SkipSelf } from '@angular/core';
import { VersionRendererComponent } from './components/version-renderer/version-renderer.component';
import { ApiTokenInterceptor } from './services/interceptors/api-token.interceptor';
import { ApiUrlnterceptor } from './services/interceptors/api-url.interceptor';
import { ErrorInterceptor } from './services/interceptors/error.interceptor';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS, MatSnackBarModule } from '@angular/material/snack-bar';
import { CommonModule } from '@angular/common';
import { MAT_TOOLTIP_DEFAULT_OPTIONS } from '@angular/material/tooltip';
import { MAT_DIALOG_SCROLL_STRATEGY } from '@angular/material/dialog';
import { BlockScrollStrategy, Overlay } from '@angular/cdk/overlay';
import { PopupsModule } from '@shared/modules/popups/popups.module';
import { MAT_RADIO_DEFAULT_OPTIONS } from '@angular/material/radio';

export function blockDialogScrolling(overlay: Overlay): () => BlockScrollStrategy {
  return () => overlay.scrollStrategies.block();
}

const providers: Provider[] = [
  {
    provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
    useValue: {
      verticalPosition: 'bottom',
      horizontalPosition: 'end',
    },
  },
  {
    provide: MAT_TOOLTIP_DEFAULT_OPTIONS,
    useValue: {
      position: 'above',
    },
  },
  {
    provide: MAT_RADIO_DEFAULT_OPTIONS,
    useValue: { color: 'primary' },
  },
  {
    provide: MAT_DIALOG_SCROLL_STRATEGY,
    useFactory: blockDialogScrolling,
    deps: [Overlay],
  },
];

@NgModule({
  imports: [CommonModule, HttpClientModule, MatSnackBarModule, PopupsModule],
  declarations: [VersionRendererComponent],
  exports: [VersionRendererComponent],
  providers: [
    ...providers,
    { provide: HTTP_INTERCEPTORS, useClass: ApiUrlnterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ApiTokenInterceptor, multi: true },
  ],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error('CoreModule is already loaded. Import it in the AppModule only');
    }
  }
}
