import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import { APP_RC } from '@core/constants/routes.constants';
import { AuthApi } from '@core/services/api/auth.api';

@Injectable({ providedIn: 'root' })
export class AuthGuard {
  canMatch(): boolean {
    const authApi: AuthApi = inject(AuthApi);

    if (authApi.isAuthorized()) return true;

    const router: Router = inject(Router);

    router.navigate([APP_RC.children.auth.children.logIn]);

    return false;
  }
}
