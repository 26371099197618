<div class="popup-footer" *ngIf="!customContent; else customFooterContent">
  <emi-divider *ngIf="config.hasBottomDivider"></emi-divider>

  <div class="popup-footer__actions">
    <emi-button *ngIf="config.hasSecondaryBtn" type="borderless" (clicked)="secondaryBtnClicked.emit()">
      {{ config.secondaryBtnText }}
    </emi-button>

    <emi-button *ngIf="config.hasPrimaryBtn" (clicked)="primaryBtnClicked.emit()" [isLoading]="isLoading">
      {{ config.primaryBtnText }}
    </emi-button>
  </div>
</div>

<ng-template #customFooterContent>
  <ng-content select="[emiCustomPopupContent]"></ng-content>
</ng-template>
