import { NgModule, Type } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';
import { InlineSVGModule } from 'ng-inline-svg-2';

import { ButtonComponent } from '@shared/components/button/button.component';
import { GenericPopupComponent } from './components/generic-popup/generic-popup.component';
import { PopupService } from './services/popup.service';
import { PopupHeaderComponent } from './components/popup-header/popup-header.component';
import { PopupFooterComponent } from './components/popup-footer/popup-footer.component';
import { PopupBodyComponent } from './components/popup-body/popup-body.component';
import { CustomPopupContentDirective } from './directives/custom-popup-content.directive';
import { DividerComponent } from '@shared/components/divider/divider.component';

const publicComponents: Type<unknown>[] = [
  GenericPopupComponent,
  PopupHeaderComponent,
  PopupFooterComponent,
  PopupBodyComponent,
];

@NgModule({
  declarations: [...publicComponents, CustomPopupContentDirective],
  imports: [CommonModule, MatDialogModule, InlineSVGModule, ButtonComponent, DividerComponent],
  exports: [...publicComponents, CustomPopupContentDirective],
  providers: [PopupService],
})
export class PopupsModule {}
