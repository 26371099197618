import { Injectable } from '@angular/core';
import { PreloadingStrategy, Route } from '@angular/router';
import { APP_RC } from '@core/constants/routes.constants';
import { EMPTY, Observable } from 'rxjs';
import { Router } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class PreloadingStrategyService implements PreloadingStrategy {
  constructor(private router: Router) {}

  preload(route: Route, load: () => Observable<unknown>): Observable<unknown> {
    if (route.path === APP_RC.children.authorizedContent.root) {
      return this.loadAuthorizedContentModule(load);
    }

    return load();
  }

  private loadAuthorizedContentModule(load: () => Observable<unknown>): Observable<unknown> {
    if (this.router.url.includes(APP_RC.children.auth.children.logIn)) {
      return load();
    }

    return EMPTY;
  }
}
