import { ChangeDetectionStrategy, Component, Input, Output, EventEmitter } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ButtonState } from '@core/enums/button.enums';
import { ButtonType } from './models/button.models';

@Component({
  selector: 'emi-button',
  standalone: true,
  imports: [CommonModule, MatProgressSpinnerModule],
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonComponent {
  @Input() type: ButtonType = 'primary';

  @Input() size: 'small' | 'large' = 'large';

  @Input() state: ButtonState = ButtonState.DEFAULT;

  @Input() isDisabled: boolean = false;

  @Input() isLoading: boolean = false;

  @Input() title: string = '';

  // Ex. number of choosen filters
  @Input() number: number = 0;

  @Output() clicked: EventEmitter<void> = new EventEmitter();

  buttonState = ButtonState;

  onClick() {
    this.clicked.emit();
  }
}
