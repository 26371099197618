type FormDataValue<T> = FormDataObject<T> | Array<FormDataValue<T>> | Blob | string;

type FormDataObject<T> = { [P in keyof T]: FormDataValue<T[P]> };

type FormDataArray<T> = Array<FormDataValue<T>>;

export class FormDataUtils {
  static transform<T>(object: FormDataObject<T>): FormData {
    const data: FormData = new FormData();

    this.handleObject(object, data);

    return data;
  }

  private static handleObject<T>(object: FormDataObject<T>, data: FormData, path: string = ''): void {
    for (const key in object) {
      const _value: FormDataValue<T[keyof T]> = object[key];

      if (_value instanceof Array) {
        this.handleArray(_value, data, this.composePath(path, key));

        continue;
      } else if (_value instanceof Object && !(_value instanceof Blob)) {
        this.handleObject(_value, data, this.composePath(path, key));

        continue;
      } else {
        data.append(this.composePath(path, key), _value);
      }
    }
  }

  private static handleArray<T>(array: FormDataArray<T>, data: FormData, path: string = ''): void {
    if (array.length === 0) {
      return data.append(this.composePath(path, ''), '');
    }

    for (let index = 0; index < array.length; index++) {
      const _value: FormDataValue<T> = array[index];

      if (_value instanceof Array) {
        this.handleArray(_value, data, this.composePath(path, `${index}`));

        continue;
      } else if (_value instanceof Object && !(_value instanceof Blob)) {
        this.handleObject(_value, data, this.composePath(path, `${index}`));

        continue;
      } else {
        data.append(this.composePath(path, `${index}`), _value);
      }
    }
  }

  private static composePath(currect: string, child: string): string {
    if (currect.length > 0) {
      return currect + `[${child}]`;
    }

    return child;
  }
}
