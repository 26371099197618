export enum ButtonType {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  BORDERLESS = 'borderless',
}

export enum ButtonState {
  DEFAULT = 'default',
  DISABLED = 'disabled',
}
