import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { GenericPopupComponent } from '../components/generic-popup/generic-popup.component';
import { DEFAULT_POPUP_PANEL_CONFIG, ERROR_CONFIG, SUCCESS_CONFIG } from '../constants/popup.constants';
import { EmiPopupConfig } from '../models/popup.models';
import { ComponentType } from '@angular/cdk/portal';

@Injectable()
export class PopupService {
  constructor(private readonly dialog: MatDialog) {}

  openSuccessPopup(data: EmiPopupConfig = {}, config: MatDialogConfig = {}): MatDialogRef<GenericPopupComponent> {
    return this.dialog.open(GenericPopupComponent, {
      ...this.mergeConfigs(config),
      data: { ...SUCCESS_CONFIG, ...data },
    });
  }

  openErrorPopup(data: EmiPopupConfig = {}, config: MatDialogConfig = {}): MatDialogRef<GenericPopupComponent> {
    return this.dialog.open(GenericPopupComponent, {
      ...this.mergeConfigs(config),
      data: { ...ERROR_CONFIG, ...data },
    });
  }

  openGenericPopup(data: EmiPopupConfig, config: MatDialogConfig = {}): MatDialogRef<GenericPopupComponent> {
    return this.dialog.open(GenericPopupComponent, { ...this.mergeConfigs(config), data });
  }

  openCustomPopup<T, D>(
    type: ComponentType<T>,
    data: EmiPopupConfig<D> = {},
    config: MatDialogConfig = {}
  ): MatDialogRef<T> {
    return this.dialog.open(type, { data, ...this.mergeConfigs(config) });
  }

  private mergeConfigs(config: MatDialogConfig): MatDialogConfig {
    return { ...DEFAULT_POPUP_PANEL_CONFIG, ...config };
  }
}
