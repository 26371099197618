import { CreateStructuralRouteFn, StructuralRoute } from '@core/models/route.models';

const createStructuralRoute: CreateStructuralRouteFn = (
  prefix: string | null = '',
  suffix: string = '',
  child?: StructuralRoute,
  paramName: string = 'id'
): StructuralRoute => {
  return {
    prefix,
    suffix,

    get route() {
      const prefix: string = `${this.prefix ? `${this.prefix}/` : ''}`;
      const suffix: string = `${this.suffix ? `/${this.suffix}` : ''}`;
      const _route: string = `${prefix}:${paramName}${suffix}`;
      return child ? `${_route}/${child.route}` : _route;
    },

    construct(param: string, prefixUrl: string = '') {
      const _route: string = `${this.prefix ? `${this.prefix}/` : ''}${param}${this.suffix ? `/${this.suffix}` : ''}`;
      const currentUrl: string = `${prefixUrl ? `${prefixUrl}/` : ''}${_route}`;
      return child ? (_param: string) => child.construct(_param, currentUrl) : currentUrl;
    },
  };
};

export const DAILY_GLIMPSE_RC = {
  root: 'daily-glimpse',
  children: {
    list: '',
    create: 'create',
    edit: createStructuralRoute(null, 'edit'),
    details: createStructuralRoute(null, 'details'),
  },
};

export const PROGRAMS_RC = {
  root: 'programs',
  children: {
    list: '',
    create: 'create',
    edit: createStructuralRoute(null, 'edit'),
  },
};

export const USER_MANAGEMENT_RC = {
  root: 'user-management',
  children: {
    list: '',
    details: createStructuralRoute(null, 'details'),
  },
};

export const MEDIA_LIBRARY_RC = {
  root: 'media-library',
  children: {
    view: '',
  },
};

export const CATEGORY_MANAGEMENT_RC = {
  root: 'category-management',
  children: {
    view: '',
  },
};

export const NOTIFICATIONS_RC = {
  root: 'notifications',
  children: {
    view: '',

    createBasic: 'create-basic',
    editBasic: createStructuralRoute(null, 'edit-basic'),
    detailsBasic: createStructuralRoute(null, 'details-basic'),

    editInvite: createStructuralRoute(null, 'edit-invite'),
    inviteDetails: createStructuralRoute(null, 'invite-details'),

    editShareTemplate: createStructuralRoute(null, 'edit-share-template'),
    shareTemplateDetails: createStructuralRoute(null, 'share-template-details'),
  },
};

export const HOME_IMAGE_RC = {
  root: 'home-image',
  children: {
    view: '',
  },
};

export const AUTH_RC = {
  root: '',
  children: {
    logIn: 'log-in',
  },
};

export const AUTHORIZED_CONTENT_RC = {
  root: '',
  children: {
    dailyGlimpse: DAILY_GLIMPSE_RC,
    userManagement: USER_MANAGEMENT_RC,
    categoryManagement: CATEGORY_MANAGEMENT_RC,
    notifications: NOTIFICATIONS_RC,
    mediaLibrary: MEDIA_LIBRARY_RC,
    homeImage: HOME_IMAGE_RC,
    programs: PROGRAMS_RC,
  },
};

export const APP_RC = {
  root: '',
  children: {
    auth: AUTH_RC,
    authorizedContent: AUTHORIZED_CONTENT_RC,
  },
};
