import { ChangeDetectionStrategy, Component, ContentChild, EventEmitter, Inject, Input, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { EmiPopupCloseEvent, EmiPopupConfig } from '../../models/popup.models';
import { PopupBodyComponent } from '../popup-body/popup-body.component';
import { PopupFooterComponent } from '../popup-footer/popup-footer.component';
import { PopupHeaderComponent } from '../popup-header/popup-header.component';

@Component({
  selector: 'emi-generic-popup',
  templateUrl: './generic-popup.component.html',
  styleUrls: ['./generic-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GenericPopupComponent {
  @Input() isLoading: boolean = false;

  @Output() primaryBtnClicked: EventEmitter<boolean> = new EventEmitter();

  @Output() secondaryBtnClicked: EventEmitter<boolean> = new EventEmitter();

  @ContentChild(PopupHeaderComponent) protected customHeader?: PopupHeaderComponent;

  @ContentChild(PopupBodyComponent) protected customBody?: PopupBodyComponent;

  @ContentChild(PopupFooterComponent) protected customFooter?: PopupFooterComponent;

  constructor(
    @Inject(MAT_DIALOG_DATA) public readonly data: EmiPopupConfig,
    private readonly dialogRef: MatDialogRef<GenericPopupComponent, EmiPopupCloseEvent>
  ) {}

  close(): void {
    this.dialogRef.close({ closeBtnClicked: true });
  }

  handlePrimaryBtnClick(): void {
    this.primaryBtnClicked.emit();

    if (this.data.closeOnButtonClick) {
      this.dialogRef.close({ primaryBtnClicked: true });
    }
  }

  handleSecondaryBtnClick(): void {
    this.secondaryBtnClicked.emit();

    if (this.data.closeOnButtonClick) {
      this.dialogRef.close({ secondaryBtnClicked: true });
    }
  }
}
