import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { ENVIRONMENT } from '@core/constants/platform/environment.token';
import { APP_VERSION } from '@core/constants/platform/app-version.token';
import { AppModule } from './app/app.module';
import { environment } from '@environment';

const { version: appVersion } = require('../package.json');

platformBrowserDynamic([
  { provide: ENVIRONMENT, useValue: environment },
  { provide: APP_VERSION, useValue: appVersion },
])
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
